const MAX_EMAIL_LENGTH = 319;
export function emailValid(email: string): boolean {
  if (!email) {
    return false;
  }
  if (email.indexOf("　") !== -1) {
    return false;
  }
  if (email.indexOf(" ") !== -1) {
    return false;
  }
  if (email.length > MAX_EMAIL_LENGTH) {
    return false;
  }
  const pattern = /.+@.+\..+/;
  return pattern.test(email);
}
