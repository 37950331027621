import React, { useContext, useEffect, useState } from "react";
import styles from "./Header.module.css";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { I18Context } from "context/I18Context";

export default function Header() {
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  const { pathname } = useLocation();
  const [showLanguage, setShowLanguage] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const { language, changeLanguage } = useContext(I18Context);
  useEffect(() => {
    if (pathname === "/") {
      const header = document.querySelector("header");
      window.addEventListener("scroll", function () {
        header?.setAttribute(
          "style",
          `background-color: rgba(255,255,255,${
            window.scrollY / 1000 >= 1 ? 1 : window.scrollY / 1000
          })`
        );
        if (window.scrollY > 10) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      });
    }
  }, [pathname]);
  // const handleLanguageChange = (
  //   e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  //   newLocale: string
  // ) => {
  //   e.preventDefault();
  //   router.push(pathname, { locale: newLocale });
  // };
  return (
    <header
      className={
        pathname === "/"
          ? `${styles.header} ${styles.transparent} ${
              isSticky ? styles.sticky_s : ""
            }`
          : `${styles.header}`
      }
    >
      <div className="container">
        <div className="row align-items-center justify-content-space-between">
          <div className="col-md-auto">
            <Link to={"/"}>
              <img
                src={
                  pathname === "/" && isSticky === false
                    ? "/images/ebisuLogo-light.svg"
                    : "/images/ebisuLogo.svg"
                }
                alt=""
                className={styles.logo}
              />
            </Link>
          </div>
          <div className="col-md-auto">
            <div className={styles.nav}>
              <nav>
                <ul>
                  <li>
                    <Link to={"/"}>{t("navbar.home")}</Link>
                  </li>
                  <li>
                    <Link to={"/about"}>{t("navbar.aboutUs")}</Link>
                  </li>
                  {/* <li>
                    <Link to={"/services"}>Services</Link>
                  </li> */}
                  <li>
                    <Link to={"/works"}>{t("navbar.portfolio")}</Link>
                  </li>
                  <li>
                    <Link to={"/recruit"}>{t("navbar.recruit")}</Link>
                  </li>
                  <li>
                    <Link to={"/contact"} className={styles.contact_link}>
                      {t("navbar.contactUs")}
                    </Link>
                  </li>
                </ul>
              </nav>
              <div
                className={styles.language_switch}
                onClick={() => {
                  setShowLanguage(!showLanguage);
                }}
              >
                <div className={styles.locale}>
                  {language.includes("en") ? "en" : "ja"}
                </div>
                <div
                  className={
                    showLanguage
                      ? `${styles.language} ${styles.active}`
                      : styles.language
                  }
                >
                  <div>{language.includes("en") ? "en" : "ja"}</div>

                  <div
                    // locale={locale === "ja" ? "en" : "ja"}
                    onClick={() =>
                      changeLanguage(language.includes("en") ? "ja" : "en")
                    }
                  >
                    {language.includes("en") ? "ja" : "en"}
                  </div>
                </div>
              </div>
              <HamburgerMenu isSticky={isSticky} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
