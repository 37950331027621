import React from "react";
import styles from "./IntroNew.module.css";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
export default function IntroNew() {
  const { t } = useTranslation("translation", { keyPrefix: "About" });
  return (
    <section className={"section c-white " + styles.section}>
      <div className="container">
        <div className="row align-items-center justify-content-space-between">
          <div className="col-md-12">
            <h1>{t("pageHeading")}</h1>
          </div>
        </div>
      </div>
    </section>
  );
}
