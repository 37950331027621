import React from "react";
import styles from "./Timeline.module.css";
import { useTranslation } from "react-i18next";
export default function Timeline() {
  const { t } = useTranslation("translation", { keyPrefix: "About" });
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 text-center">
            <div className={styles.content}>
              <h3 className="pre-heading">{t("timeline.preHeading")}</h3>
              <h2 className="heading mb-2">{t("timeline.heading")}</h2>
              <p>{t("timeline.description")}</p>
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.timeline}>
              <div className={styles.box}>
                <div className={styles.card}>
                  <span className={styles.pointer}></span>
                  <div className={styles.info}>
                    <h4>{t("timeline.timeline1Title")}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("timeline.timeline1"),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <span className={styles.pointer}></span>
                  <div className={styles.info}>
                    <h4>{t("timeline.timeline2Title")}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("timeline.timeline2"),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <span className={styles.pointer}></span>
                  <div className={styles.info}>
                    <h4>{t("timeline.timeline3Title")}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("timeline.timeline3"),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <span className={styles.pointer}></span>
                  <div className={styles.info}>
                    <h4>{t("timeline.timeline4Title")}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("timeline.timeline4"),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <span className={styles.pointer}></span>
                  <div className={styles.info}>
                    <h4>{t("timeline.timeline5Title")}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("timeline.timeline5"),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
