import React, { Fragment } from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactDetails from "../Contact/ContactDetails/ContactDetails";
import { FaAngleRight } from "react-icons/fa6";
export default function Footer() {
  const { t } = useTranslation("translation", { keyPrefix: "Footer" });
  return (
    <Fragment>
      <footer className={"section " + styles.footer}>
        <div className="container">
          <div className="row justify-content-space-between">
            <div className={"col-md-4 " + styles.logo}>
              <img src={"/images/ebisuLogo.svg"} alt="" />
            </div>
            <div className="col-md-4">
              <h4 className={styles.header}>Links</h4>
              <ul className={styles.links}>
                <li>
                  <FaAngleRight />
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <FaAngleRight />
                  <Link to={"/about"}>About</Link>
                </li>
                <li>
                  <FaAngleRight />
                  <Link to={"/works"}>Works</Link>
                </li>
                <li>
                  <FaAngleRight />
                  <Link to={"/recruit"}>Recruit</Link>
                </li>
                <li>
                  <FaAngleRight />
                  <Link to={"/contact"}>Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className={styles.header}>Contact</h4>
              <ContactDetails />
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.footer_bottom}>
        <div className="container">
          <div className="row justify-content-space-between">
            <p>
              Copyright @ {new Date().getFullYear()} ebisu, Inc. All Rights
              Reserved.
            </p>
            <Link to={"/privacy"}>{t("privacyLink")}</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
