import React from "react";

export default function GoogleMap() {
  return (
    <section className="section pb-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.867795203647!2d135.49191911523178!3d34.683285980439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e6fc52553b5b%3A0x2db681e562a6083f!2z44CSNTUwLTAwMDUg5aSn6Ziq5bqc5aSn6Ziq5biC6KW_5Yy66KW_5pys55S677yR5LiB55uu77yR77yT4oiS77yT77yY!5e0!3m2!1sja!2sjp!4v1536565824902"
        width={"100%"}
        height={500}
      ></iframe>
    </section>
  );
}
