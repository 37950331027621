import React, { useContext, useEffect, useState } from "react";
import styles from "./FeaturedWorks.module.css";
import Button from "components/Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import works_en from "../../../data/works_en.json";
import works_ja from "../../../data/works_ja.json";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { I18Context } from "context/I18Context";
import axios from "axios";
export default function FeaturedWorks() {
  const { language } = useContext(I18Context);

  const [works, setWorks] = useState<any>(undefined);
  useEffect(() => {
    // getCurrLan();
    // getDataFromServer();
    setWorks({ en: { ...works_en }, ja: { ...works_ja } });
  }, []);

  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  const url = window.location.origin;
  async function getDataFromServer() {
    try {
      const works_en = await axios(`${url}/json/works_en.json`);
      const works_ja = await axios(`${url}/json/works_ja.json`);
      setWorks({ en: works_en.data, ja: works_ja.data });
    } catch (error) {
      setWorks({ en: { ...works_en }, ja: { ...works_ja } });
      console.log(error);
    }
  }
  return (
    <section className={"section-100 c-white " + styles.section}>
      <div className="container">
        <div>
          <div className={"row align-items-center"}>
            <div className={"col-md-4 " + styles.content}>
              <h3 className="pre-heading">{t("featuredWorks.preHeading")}</h3>
              <h2 className="heading mb-2 u-left">Featured Works</h2>
              <div className="mb-2">
                <p>{t("featuredWorks.text1")}</p>
                <p>{t("featuredWorks.text2")}</p>
              </div>
              <Link to={"/works"}>
                <Button>{t("featuredWorks.buttonText")}</Button>
              </Link>
            </div>
            <div className={"col-md-8"}>
              <div>
                <Swiper
                  modules={[Autoplay, Pagination]}
                  autoplay= {{delay:4000, pauseOnMouseEnter:true}}
                  pagination={{ clickable: true}}
                  slidesPerView={2}
                  className={styles.swiper}
                  spaceBetween={10}
                  breakpoints={{
                    768: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {language &&
                  works &&
                  works[language.includes("en") ? "en" : "ja"]
                    ? works[
                        language.includes("en") ? "en" : "ja"
                      ].featuredWorks.map((work: any, idx: number) => (
                        <SwiperSlide key={idx} className={styles.slide}>
                          <div className={styles.work_wrapper}>
                            <div>
                              <img src={work.image} alt="" />
                            </div>
                            <div>
                              <h3>{work.title}</h3>
                              <p>{`${work["description"]} `}</p>
                              <p>Language: {work.language}</p>
                              <p>Release: {work.date}</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    : "..."}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
