import React from "react";
import styles from "./page.module.css";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className={styles.heading}>
              404<span>PAGE NOT FOUND</span>
            </h1>
            <Link to={"/"}>Return to Home</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
