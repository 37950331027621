import FormSection from "components/Contact/FormSection/FormSection";
import GoogleMap from "components/Contact/GoogleMap/GoogleMap";
import Hero from "components/Contact/Hero/Hero";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function ContactPage() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("Title.contact")}</title>
        <meta
          name="description"
          content="ヱビス株式会社ウェブサイト。ヱビスはスマートフォンやIoT、クラウドを活用したサービスを提供する会社です。"
        />
        <meta
          name="keywords"
          content="android,iOS,web,java,C#,C++,IoT,AI,スマートスピーカー,Kotlin,Swift,ソフトウェア,スマートフォン,スマホ,アプリ,大阪"
        />
      </Helmet>
      <Hero />
      <FormSection />
      <GoogleMap />
    </div>
  );
}
