import React from "react";
import styles from "./Button.module.css";
import { HiArrowRight } from "react-icons/hi2";
export default function Button({ children }: { children: string }) {
  return (
    <button className={styles.button}>
      <span>{children}</span>
      <HiArrowRight />
    </button>
  );
}
