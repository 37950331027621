import { useTranslation } from "react-i18next";
import React from "react";
import styles from "./VideoHero.module.css";
import { HiArrowRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
export default function VideoHero() {
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  return (
    <div className={styles.wrapper}>
      <div className={styles.video}>
        <video
          src={"/images/hero-video1.mp4"}
          autoPlay
          muted
          loop
          controls={false}
          playsInline
        ></video>
      </div>
      <section className={"section " + styles.hero}>
        <div className="container">
          <div className="row align-items-center justify-content-space-between row-g-2">
            <div className="col-md-5">
              <h1 dangerouslySetInnerHTML={{ __html: t("hero.heading") }} />

              <h3 className="mb-2">
                {t("hero.text2")}
                <br />
                {t("hero.text3")}
              </h3>
              <Link to={"/about"}>
                <button className={styles.button}>
                  <span>{t("hero.buttonText")}</span>
                  <HiArrowRight />
                </button>
              </Link>
            </div>
            {/* <div className="col-md-6">
              <PlayIcon />
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
