import React from "react";
import styles from "./CompanyProfile.module.css";
import { RiBuilding2Line } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { IoPersonCircleOutline } from "react-icons/io5";
import { TbLocation } from "react-icons/tb";
import { MdAlternateEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { LiaFaxSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";
export default function CompanyProfile() {
  const { t } = useTranslation("translation", { keyPrefix: "About" });
  const profiles = [
    {
      title: t("companyProfile.companyName.title"),
      message: t("companyProfile.companyName.message"),
      icon: <RiBuilding2Line />,
    },
    {
      title: t("companyProfile.establishment.title"),
      message: t("companyProfile.establishment.message"),
      icon: <RxCalendar />,
    },
    {
      title: t("companyProfile.ceo.title"),
      message: t("companyProfile.ceo.message"),
      icon: <IoPersonCircleOutline />,
    },
    {
      title: t("companyProfile.location.title"),
      message: t("companyProfile.location.message"),
      icon: <TbLocation />,
    },
    {
      title: t("companyProfile.emailTitle"),
      message: "info@ebisuinc.jp",
      icon: <MdAlternateEmail />,
    },
    {
      title: t("companyProfile.phoneTitle"),
      message: "06-6537-7702",
      icon: <MdOutlinePhone />,
    },
    {
      title: t("companyProfile.faxTitle"),
      message: "06-6537-7703",
      icon: <LiaFaxSolid />,
    },
  ];
  return (
    <section className={styles.section + " section"}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 text-center">
            <h3 className="pre-heading">Company Profile</h3>
            <h2 className="heading mb-4">Outline</h2>
          </div>
          <div className="col-md-12">
            <div className={styles.profiles + " row"}>
              {profiles.map((profile, idx) => (
                <div className={styles.profile} key={idx}>
                  <div className={styles.icon}>{profile.icon}</div>
                  <div className={styles.title}>{profile.title}</div>
                  <div className={styles.message}>{profile.message}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
