import React from "react";
import styles from "./Contact.module.css";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function Contact() {
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  return (
    <section className={"section-100 c-white " + styles.contact}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="pre-heading mb-2">{t("cta.preHeading")}</h3>
            <h2 className="heading no-u mb-4">{t("cta.heading")}</h2>
            <Link to={"/contact"}>
              <button className={styles.btn}>
                <span>{t("cta.buttonText")}</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
