import AllWorks from "components/Works/AllWorks/AllWorks";
import Hero from "components/Works/Hero/Hero";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
export default function WorksPage() {
  const { t } = useTranslation();
  
  return (
    <div>
      <Helmet>
        <title>{t("Title.portfolio")}</title>
        <meta
          name="description"
          content="ヱビス株式会社ウェブサイト。ヱビスはスマートフォンやIoT、クラウドを活用したサービスを提供する会社です。"
        />
        <meta
          name="keywords"
          content="android,iOS,web,java,C#,C++,IoT,AI,スマートスピーカー,Kotlin,Swift,ソフトウェア,スマートフォン,スマホ,アプリ,大阪"
        />
      </Helmet>
      <Hero />
      <AllWorks />
    </div>
  );
}
