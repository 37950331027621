import { FirstLoadContext } from "context/firstTimeLoadContent";
import React, { Fragment, useContext, useEffect, useState } from "react";

const LOADER_STORAGE_KEY = "firstPageLoad";

export default function Loader() {
  const [loader, setLoader] = useState(true);
  const { isFirstLoad, setIsFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    if (isFirstLoad) {
      document.querySelector("html")?.setAttribute("style", "overflow: hidden");

      setTimeout(() => {
        setLoader(false);
        document.querySelector("html")?.removeAttribute("style");
        setIsFirstLoad(false);
      }, 1700);
    } else {
      setLoader(false);
    }

    return () => {
      document.querySelector("html")?.removeAttribute("style");
    };
  }, []);

  return (
    <Fragment>
      {loader ? (
        <div className="loader">
          <object type="image/svg+xml" data="/images/ebisu-animate.svg">
            svg-animation
          </object>
        </div>
      ) : null}
    </Fragment>
  );
}
