import { useTranslation } from "react-i18next";
import React from "react";

export default function Intro() {
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  return (
    <section className="section-box">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2
              className="text-center"
              style={{
                fontWeight: "normal",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: t("intro") }}></div>
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
