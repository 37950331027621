import React from "react";
import { useEffect, useRef } from "react";
import { Player } from "@lordicon/react";
import styles from "./Features.module.css";
import { useTranslation } from "react-i18next";

export default function Features() {
  const playerRef = useRef<any>(null);
  const devOpsRef = useRef<any>(null);
  const backendRef = useRef<any>(null);
  const vrRef = useRef<any>(null);
  const qaRef = useRef<any>(null);
  const DEVOPICON = require("../../../icons/wired-outline-680-it-developer.json");
  const BACKEND = require("../../../icons/wired-outline-1331-repository.json");
  const VR = require("../../../icons/wired-outline-1335-qr-code.json");
  const QA = require("../../../icons/wired-outline-1330-rest-api.json");
  interface Features {
    title: string;
    description: string;
    icon?: any;
    refName?: any;
  }
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  const features: Features[] = [
    {
      title: "Mobile Application",
      description: t("features.features1"),

      icon: <Player ref={devOpsRef} icon={DEVOPICON} size={100} />,
      refName: devOpsRef,
    },
    {
      title: "Web Application",
      description: t("features.features2"),
      icon: <Player ref={backendRef} icon={BACKEND} size={100} />,
      refName: backendRef,
    },
    {
      title: "Backend",
      description: t("features.features3"),
      icon: <Player ref={vrRef} icon={VR} size={100} />,
      refName: vrRef,
    },
    // {
    //   title: "QA & Testing",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, illo? Exercitationem illo nobis, et velit ratione esse, eum molestiae eos est libero dolores consequuntur mollitia, possimus quisquam provident aut incidunt!",
    //   icon: <Player ref={qaRef} icon={QA} size={100} />,
    //   refName: qaRef,
    // },
  ];
  function handleHover(refName: any) {
    refName.current?.playFromBeginning();
  }
  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-4">
            <h3 className="pre-heading">{t("features.preHeading")}</h3>
            <h2 className="heading">{t("features.heading")}</h2>
          </div>
          {features.map((feature, idx) => (
            <div className="col-md-4" key={idx}>
              <div
                onMouseEnter={() => {
                  handleHover(feature.refName);
                }}
                className={`${styles.features} ${styles["feature_" + idx]}`}
              >
                <div className={styles.icon}>
                  {feature.icon ? feature.icon : ""}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
