import { useEffect } from "react";
import { useLocation, NavigationType, useNavigationType } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };
  
  const isPop = useBackButton();

  const scrollToTopBack = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); 
  };

  useEffect(() => {
    
      setTimeout(() => {
        scrollToTop(); 
      }, 100); 
  }, [pathname]);

  useEffect(() => {
    if (isPop) {
      setTimeout(() => {
        scrollToTopBack(); 
      }, 100);
    }
  }, [isPop]);

  return null;
};

export default ScrollToTop;
