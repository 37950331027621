import React, { Fragment } from "react";
import styles from "./page.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
export default function PrivacyPage() {
    const { t } = useTranslation();
  return (
    
    <Fragment>
      <ScrollToTop />
      <section className={"section c-white " + styles.section}>
      <Helmet>
        <title>{t("Title.privacy")}</title>
        <meta
          name="description"
          content="ヱビス株式会社ウェブサイト。ヱビスはスマートフォンやIoT、クラウドを活用したサービスを提供する会社です。"
        />
        <meta
          name="keywords"
          content="android,iOS,web,java,C#,C++,IoT,AI,スマートスピーカー,Kotlin,Swift,ソフトウェア,スマートフォン,スマホ,アプリ,大阪"
        />
      </Helmet>
        <div className="container">
          <div className="row align-items-center justify-content-space-between">
            <div className="col-md-12">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h4 className={styles.sub_heading}>
                1.個人情報の保護についての考え方
              </h4>
              <p>
                ヱビス株式会社（以下、「当社」という）は、お取引先様を始めとする、当社に個人情報をご提供される全ての方（以下、「ご本人」という）の個人情報の適正な保護を重大な責務と認識し、この責務を果すために、次の方針のもとで個人情報を取り扱います。
              </p>
              <ul className={styles.ul}>
                <li>
                  個人情報に適用される個人情報の保護に関する法律その他の関係法令を遵守するとともに、一般に公正妥当と認められる個人情報の取扱いに関する慣行に準拠し適切に取扱います。また、適宜取扱いの改善に努めます。
                </li>
                <li>
                  個人情報の取扱いに関する規定を明確にし、従業員に周知徹底します。また、委託先に対しても必要かつ適切な監督を行います。
                </li>
                <li>
                  個人情報の取扱いに際しては、利用目的を特定して通知または公表し、その利用目的に従って個人情報を取り扱います。
                </li>
                <li>
                  個人情報の漏洩、紛失、改ざん等を防止するため、必要な対策を講じて適切な管理を行います。
                </li>
                <li>
                  保有する個人情報について、ご本人からの開示、訂正、削除、利用停止の依頼を所定の窓口でお受けして、誠意をもって対応します。
                </li>
              </ul>
              <p></p>
              <h4 className={styles.sub_heading}>2.個人情報の利用目的</h4>
              <p>
                当社は、法令等に照らし適正な手段により取得した個人情報につき、当社が営む事業において以下の目的のため、必要な範囲で利用させて頂くことがあります。
              </p>
              <ul className={styles.ul}>
                <li>製品・サービスのご案内、ご提供</li>
                <li>製品・サービスの開発</li>
                <li>原材料・物品・サービスの購入</li>
                <li>ご本人からのお問合せへの対応</li>
              </ul>
              <h4 className={styles.sub_heading}>3.第三者への開示・提供</h4>
              <p>
                当社は、委託先への提供の場合および以下のいずれかに該当する場合を除き、個人データを第三者へ開示または提供しません。
              </p>
              <ul className={styles.ul}>
                <li>ご本人の同意がある場合</li>
                <li>
                  統計的なデータなど本人を識別することが出来ない状態で開示・提供する場合
                </li>
                <li>法令に基づき開示・提供を求められた場合</li>
                <li>
                  人の生命、身体または財産の保護のために必要な場合であって、ご本人の同意を得ることが困難である場合
                </li>
                <li>
                  国または地方公共団体等が、公的な事務を実施する上で、協力する必要がある場合であって、ご本人の同意を得ることにより、当該事務の遂行に支障を及ぼすおそれがある場合個人データを特定の者と共有する場合であって、共同利用する個人データの項目、共同利用する者の範囲、利用目的管理責任者を、あらかじめご本人に通知し、または公表している場合
                </li>
              </ul>

              <h4 className={styles.sub_heading}>4.開示</h4>
              <p>
                当社の保有個人データに関して、ご本人自身の情報の開示をご希望される場合には、お申し出いただいた方がご本人である事を確認した上で、合理的な期間および範囲で回答します。
              </p>
              <h4 className={styles.sub_heading}>5.訂正・削除等</h4>
              <p>
                当社の保有個人データに関して、ご本人自身の情報について訂正、追加または削除を希望される場合には、お申し出いただいた方がご本人である事を確認した上で、事実と異なる内容がある場合には、合理的な期間および範囲で情報の訂正、追加または削除をします。
              </p>
              <h4 className={styles.sub_heading}>6.利用停止・消去</h4>
              <p>
                当社の保有個人データに関して、ご本人自身の情報の利用停止または消去をご希望される場合には、お申し出いただいた方がご本人である事を確認した上で、合理的な期間および範囲で利用停止または消去します。これらの情報の一部または全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供が出来なくなることがありますので、ご理解とご協力を賜りますようお願い申し上げます。
              </p>
              <p>
                （なお関係法令に基づき保有しております情報については、利用停止または消去のお申し出には応じられない場合があります。）
              </p>
              <h4 className={styles.sub_heading}>7.開示等の受付窓口</h4>
              <p>
                当社の保有個人データに関する、上記４．５．６．のお申し出およびその他の個人情報に関するお問い合わせは、以下の窓口にて受付けます。
              </p>
              <p>
                個人情報をご提供いただいた際に当社より通知した受付窓口、もしくは当社とのお取引がある場合にはお取引窓口にて申し受けます。なお、受付窓口がおわかりにならない場合には、下記までお問い合わせください。
              </p>
              <p>
                ヱビス株式会社
                <br />
                電話：06-6537-7702 / FAX：06-6537-7703
                <br />
                <div className={styles.contact}>
                <a href='/contact'>CONTACT</a>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
