import React from "react";
import styles from "./Recruit.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Recruit() {
  const { t } = useTranslation("translation");
  const jobs = [
    {
      opening: t("Recruit.1.message"),
      skills: t("Recruit.2.message"),
      employmentType: t("Recruit.3.message"),
      salary: t("Recruit.4.message"),
      workingHours: t("Recruit.5.message"),
      benefits: t("Recruit.6.message"),
      holidays: t("Recruit.7.message"),
    },
  ];
  return (
    <section className="section">
      <Helmet>
        <title>{t("Title.recruit")}</title>
        <meta
          name="description"
          content="ヱビス株式会社ウェブサイト。ヱビスはスマートフォンやIoT、クラウドを活用したサービスを提供する会社です。"
        />
        <meta
          name="keywords"
          content="android,iOS,web,java,C#,C++,IoT,AI,スマートスピーカー,Kotlin,Swift,ソフトウェア,スマートフォン,スマホ,アプリ,大阪"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {jobs.map((job, idx) => (
              <div key={idx} className={styles.job}>
                <h4>
                  <span>{t("Recruit.1.title")}:</span> {job.opening}
                </h4>
                <h4>
                  <span>{t("Recruit.2.title")}:</span>
                </h4>
                <ul>
                  {job.skills.split("Recruit.|").map((skill, id) => (
                    <li key={id}>{skill}</li>
                  ))}
                </ul>
                <h4>
                  <span>{t("Recruit.3.title")}:</span> {job.employmentType}
                </h4>
                <h4>
                  <span>{t("Recruit.4.title")}:</span> {job.salary}
                </h4>
                <h4>
                  <span>{t("Recruit.5.title")}:</span> {job.workingHours}
                </h4>
                <h4>
                  <span>{t("Recruit.6.title")}:</span>
                </h4>
                <ul>
                  {job.benefits.split("Recruit.|").map((benefit, id) => (
                    <li key={id}>{benefit}</li>
                  ))}
                </ul>
                <h4>
                  <span>{t("Recruit.7.title")}:</span>
                </h4>
                <ul>
                  {job.holidays.split("Recruit.|").map((holiday, id) => (
                    <li key={id}>{holiday}</li>
                  ))}
                </ul>
                <h4>
                  <span>{t("Recruit.8.title")}: </span>
                  {/* {t.rich("8.message", {
                    link: (chunks) => <Link href={"/contact"}>{chunks}</Link>,
                  })} */}
                  <div
                    dangerouslySetInnerHTML={{ __html: t("Recruit.8.message") }}
                  ></div>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
