import React from "react";
import styles from "./Companies.module.css";
import { useTranslation } from "react-i18next";

export default function Companies() {
  const { t } = useTranslation("translation", { keyPrefix: "About" });
  return (
    <section className="section">
      <div className="container">
        <div className={"row " + styles.sec}>
          <div className="col-md-6">
            <h3 className="pre-heading">{t("companies.preHeading")}</h3>
            <h2 className="heading u-left mb-2">{t("companies.heading")}</h2>

            <div className={styles.kokoro}>
              <img src={"/images/kokoro.svg"} className={styles.image} />
              <p>{t("companies.description")}</p>
              <a
                href="https://www.kokorocode.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("companies.buttonText")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
