import { createContext, useState } from "react";

const FirstLoadContext = createContext<any>(null);

const FirstLoadProvider = (props: any) => {
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  return (
    <FirstLoadContext.Provider value={{ isFirstLoad, setIsFirstLoad }}>
      {props.children}
    </FirstLoadContext.Provider>
  );
};

export { FirstLoadContext, FirstLoadProvider };
