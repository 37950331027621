import React, { Fragment } from "react";
import { TbLocation } from "react-icons/tb";
import { MdAlternateEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import styles from "./ContactDetails.module.css";
import { useTranslation } from "react-i18next";
export default function ContactDetails() {
  const { t } = useTranslation("translation", { keyPrefix: "Contact" });
  const contactInfo = [
    {
      title: "Location",
      message: t("location"),
      icon: <TbLocation />,
    },
    {
      title: "Email",
      message: "info@ebisuinc.jp",
      link: "mailto:info@ebisuinc.jp",
      icon: <MdAlternateEmail />,
    },
    {
      title: "Phone Number",
      message: "06-6537-7702",
      link: "tel:06-6537-7702",
      icon: <MdOutlinePhone />,
    },
  ];
  return (
    <Fragment>
      <div>
        <address>
          {contactInfo.map((info, idx) => (
            <Fragment key={idx}>
              {info.link ? (
                <a href={info.link} className={styles.info}>
                  <div>{info.icon}</div>
                  <div>{info.message}</div>
                </a>
              ) : (
                <div key={idx} className={styles.info}>
                  <div>{info.icon}</div>
                  <div>{info.message}</div>
                </div>
              )}
            </Fragment>
          ))}
        </address>
      </div>
    </Fragment>
  );
}
