import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export const I18Context = createContext<any>(null);
export const I18ContextProvider = ({ children }: any) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  function changeLanguage(language: string) {
    setLanguage(language);
    i18n.changeLanguage(language);
  }
  return (
    <I18Context.Provider value={{ language, changeLanguage }}>
      {children}
    </I18Context.Provider>
  );
};
