import React from "react";
import styles from "./Hero.module.css";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
export default function Hero() {
  const { t } = useTranslation("translation", { keyPrefix: "Works" });
  return (
    <section className={"section " + styles.hero}>
      <div className="container">
        <div className="row align-items-center justify-content-space-between">
          <div className="col-md-12 c-white">
            <div className={styles.hero_content}>
              <h1>{t("pageHeading")}</h1>
            </div>
          </div>
          {/* <div className="col-md-5">
            <img src={/images/Pastel_9.png"} alt="" />
          </div> */}
        </div>
      </div>
    </section>
  );
}
