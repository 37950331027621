import React, { useContext, useEffect, useState } from "react";
import styles from "./AllWorks.module.css";
import works_en from "../../../data/works_en.json";
import works_ja from "../../../data/works_ja.json";
import { useTranslation } from "react-i18next";
import { I18Context } from "context/I18Context";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function AllWorks() {
  const { pathname } = useLocation(); 
  const { t } = useTranslation("translation", { keyPrefix: "Works" });
  const { language } = useContext(I18Context);
  const [works, setWorks] = useState<any>(undefined);

  useEffect(() => {
    getDataFromServer().then(() => {
      window.scrollTo(0, 0); 
    });
  }, []);

  const url = window.location.origin;

  async function getDataFromServer() {
    try {
      const works_en = await axios(`${url}/json/works_en.json`);
      const works_ja = await axios(`${url}/json/works_ja.json`);
      setWorks({ en: works_en.data, ja: works_ja.data });
    } catch (error) {
      console.error("データ取得に失敗しました:", error);
      setWorks({ en: { ...works_en }, ja: { ...works_ja } });
    }
  }
  // const getDataFromServer = () => {
  //   setWorks({ en: works_en, ja: works_ja });
  // };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-12 text-center">
              <h3 className="pre-heading">{t("preHeading")}</h3>
              <h2 className="heading">{t("heading")}</h2>
            </div>
          </div>
          <div className="row">
            {language && works && works[language.includes("en") ? "en" : "ja"]
              ? works[language.includes("en") ? "en" : "ja"].featuredWorks.map(
                  (work: any, id: number) => (
                    <div
                      className={
                        styles.featuredWork +
                        ` ${work.gallery?.length ? styles.hasImage : ""}`
                      }
                      key={id}
                    >
                      <div className={styles.wrapper}>
                        <div className={styles.content}>
                          <img
                            src={work.image}
                            alt={work.title}
                            className={styles.logo}
                          />
                          <h4>{work.title}</h4>
                          <div>
                            <span>{work.category}</span>
                            {work.language &&
                              work.language
                                .split(/[\/,]+/)
                                .map((language: string, id: number) => (
                                  <span key={id}>{language}</span>
                                ))}
                          </div>
                          <p>{work.description}</p>
                          {work.phase && <p>{work.phase}</p>}
                          <p>{work.period}</p>
                        </div>
                        {work.gallery && (
                          <div className={styles.images}>
                            <img src={work.gallery[0]} alt={work.title} />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )
              : "..."}
          </div>
          <div className="row">
            {language && works && works[language.includes("en") ? "en" : "ja"]
              ? works[language.includes("en") ? "en" : "ja"].allWorks.map(
                  (work: any, id: number) => (
                    <div className={"col-md-4 " + styles.workWrapper} key={id}>
                      <div className={styles.work}>
                        <h4>{work.name}</h4>
                        <div>
                          <span>{work.category}</span>
                          {work.language &&
                            work.language
                              .split(/[\/,]+/)
                              .map((language: any, id: number) => (
                                <span key={id}>{language}</span>
                              ))}
                        </div>
                        <p>{work.description}</p>
                        {work.phase && <p>{work.phase}</p>}
                        <p>{work.period}</p>
                      </div>
                    </div>
                  )
                )
              : "..."}
          </div>
        </div>
      </section>
    </>
  );
}
