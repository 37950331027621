import React from "react";
import styles from "./FromCeo.module.css";
import { useTranslation } from "react-i18next";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
export default function FromCeo() {
  const { t } = useTranslation("translation", { keyPrefix: "About" });
  return (
    <section className="section">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* <div className="col-md-3">
            <img src={/images/ceo.jpg"} alt="" className={styles.image} />
          </div> */}
          <div className="col-md-9">
            <div className={styles.message}>
              <div className={styles.quote}>
                <BiSolidQuoteAltLeft />
              </div>
              <h3
                className=" mb-2"
                dangerouslySetInnerHTML={{ __html: t("fromCEO.heading") }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: t("fromCEO.description"),
                }}
              />
              <div className={styles.credentials}>
                <div>
                  <img src={"/images/ebisu-icon.svg"} alt="" />
                </div>
                <div>
                  <span>{t("fromCEO.designation")}</span>
                  <span>{t("fromCEO.name")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
