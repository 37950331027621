import React, { Fragment } from "react";
import "./App.css";
import Loader from "components/Home/Loader/Loader";
import { Route, Routes } from "react-router-dom";
import Home from "pages/home/page";
import AboutPage from "pages/about/page";
import PrivacyPage from "pages/privacy/page";
import WorksPage from "pages/works/page";
import Recruit from "components/Recruit/Recruit/Recruit";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import ContactPage from "pages/contact/page";
import NotFound from "pages/not-found/page";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <Fragment>
      <Header />
      <Loader />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/works" element={<WorksPage />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Fragment>
  );
}

export default App;
