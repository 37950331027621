import React, { useEffect } from "react";
import styles from "./Services.module.css";
import { useRef } from "react";
import { Player } from "@lordicon/react";
import { useTranslation } from "react-i18next";
import TECHICON from "../../../icons/tech.json";
import UX from "../../../icons/ux.json";
import SETTINGS from "../../../icons/settings.json";
import QUALITY from "../../../icons/quality.json";
import SUPPORT from "../../../icons/support.json";
export default function Services() {
  const techRef: any = useRef(null);
  const uxRef: any = useRef(null);
  const settingsRef: any = useRef(null);
  const qualityRef: any = useRef(null);
  const supportRef: any = useRef(null);
  const { t } = useTranslation("translation", { keyPrefix: "Index" });
  // function handleHover(refName: any) {
  //   refName.current?.playFromBeginning();
  // }
  const services = [
    {
      title: t("services.1.title"),
      description: t("services.1.message"),
      icon: <Player ref={techRef} icon={TECHICON} size={60} colorize="#fff" />,
      refName: techRef,
    },
    {
      title: t("services.2.title"),
      description: t("services.2.message"),
      icon: <Player ref={uxRef} icon={UX} size={60} colorize="#fff" />,
      refName: uxRef,
    },
    {
      title: t("services.3.title"),
      description: t("services.3.message"),
      icon: (
        <Player ref={settingsRef} icon={SETTINGS} size={60} colorize="#fff" />
      ),
      refName: settingsRef,
    },
    {
      title: t("services.4.title"),
      description: t("services.4.message"),
      icon: (
        <Player ref={qualityRef} icon={QUALITY} size={60} colorize="#fff" />
      ),
      refName: qualityRef,
    },
    {
      title: t("services.5.title"),
      description: t("services.5.message"),
      icon: (
        <Player ref={supportRef} icon={SUPPORT} size={60} colorize="#fff" />
      ),
      refName: supportRef,
    },
  ];
  useEffect(() => {
    // for (var service of services) {
    //   handleHover(service.refName);
    // }
  }, []);
  return (
    <section className={"section " + styles.section}>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-12 text-center">
            <h3 className="pre-heading">{t("services.preHeading")}</h3>
            <h2 className="heading">{t("services.heading")}</h2>
          </div>
        </div>
        <div className="row">
          {services.map((service, id) => {
            return (
              <div className={"col-md-4 " + styles.card_wrapper} key={id}>
                <div
                  className={styles.card}
                  //onMouseEnter={() => handleHover(service.refName)}
                >
                  <div className={styles.images}>{service.icon}</div>
                  <span>0{id + 1}</span>
                  <h4 className="card-heading">{service.title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: service.description,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
