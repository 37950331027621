import Contact from "components/Home/Contact/Contact";
import FeaturedWorks from "components/Home/FeaturedWorks/FeaturedWorks";
import Features from "components/Home/Features/Features";
import Intro from "components/Home/Intro/Intro";
import Services from "components/Home/Services/Services";
import VideoHero from "components/Home/VideoHero/VideoHero";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  return (
    <main>
      <Helmet>
        <title>{t("Title.home")}</title>
      </Helmet>
      <VideoHero />
      <Services />
      <Intro />
      <Features />
      <FeaturedWorks />
      <Contact />
    </main>
  );
}
