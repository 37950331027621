import React from "react";
import CompanyProfile from "components/About/CompanyProfile/CompanyProfile";
import FromCeo from "components/About/FromCeo/FromCeo";
import IntroNew from "components/About/IntroNew/IntroNew";
import Timeline from "components/About/Timeline/Timeline";

import Companies from "components/About/Companies/Companies";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function AboutPage() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("Title.about")}</title>
        <meta
          name="description"
          content="ヱビス株式会社ウェブサイト。ヱビスはスマートフォンやIoT、クラウドを活用したサービスを提供する会社です。"
        />
        <meta
          name="keywords"
          content="android,iOS,web,java,C#,C++,IoT,AI,スマートスピーカー,Kotlin,Swift,ソフトウェア,スマートフォン,スマホ,アプリ,大阪"
        />
      </Helmet>
      <IntroNew />
      <FromCeo />
      <Timeline />
      <CompanyProfile />
      <Companies />
    </div>
  );
}
